export const USER_MENU = [
    {
        name: 'Nova solicitude',
        url: '/solicitudes/nova',
        icon: 'fas fa-plus-circle'
    },
    {
        name: 'Solicitudes',
        icon: 'fas fa-folder',
        url: '/solicitudes/',
        children: [
            {
                name: 'Borradores',
                url: '/solicitudes/lista/borrador',
                icon: 'fas fa-save'
            },
            {
                name: 'Pendentes',
                url: '/solicitudes/lista/pendentes',
                icon: 'fas fa-exclamation-circle'
            },
            {
                name: 'Presentadas',
                url: '/solicitudes/lista/presentadas',
                icon: 'fas fa-file-contract'
            },
            {
                name: 'Arquivo',
                url: '/solicitudes/lista/arquivo',
                icon: 'fas fa-history'
            }
        ]
    }

];