export const ADMIN_MENU = [
    {
        title: true,
        name: 'Informes',
        url: '/admin/informes/'

    },
    {
        name: 'Activos',
        url: '/admin/informes/activos',
        icon: 'fas fa-check-circle'
    },
    {
        name: 'Pendentes',
        url: '/admin/informes/pendentes',
        icon: 'fas fa-exclamation-circle'
    },
    {
        name: 'Arquivo',
        url: '/admin/informes/arquivo',
        icon: 'fas fa-history'
    },
    {
        title: true,
        name: 'Usuarios'
    },
    {
        name: 'Usuarios',
        url: '/admin/users',
        icon: 'fas fa-users'
    },
    {
        name: 'Consellerías',
        url: '/admin/consellerias',
        icon: 'fas fa-landmark'
    },
    {
        title: true,
        name: 'Estatísticas'
    },
    {
        name: 'Solicitudes',
        url: '/admin/estatisticas/solicitudes',
        icon: 'fas fa-chart-pie'
    },
    {
        name: 'Informes',
        url: '/admin/estatisticas/informes',
        icon: 'fas fa-chart-pie'
    },
    {
        title: true,
        name: 'Configuración'
    },
    {
        name: 'Axustes',
        url: '/admin/configuracion',
        icon: 'fas fa-cogs'
    }
];
