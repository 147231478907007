/* tslint:disable */
import { Page, Pageable, Slice, Sort } from 'fw-core';

import { Injectable, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
export interface Dictionary<V> {
    [key: string]: V;
}

export type Calendar = {};
export type Instant = {};

export enum Rol { ADMINISTRADOR = "ADMINISTRADOR", USUARIO = "USUARIO" }

export interface AccountDTO {
    authorities?: string[];
    avatar?: FwFileDTO;
    avatarUrl?: string;
    conselleria?: ItemDTO;
    email?: string;
    firstName?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    loginUserOriginal?: string;
    rol?: Rol;
    userId?: number;
    validated?: boolean;
    version?: number;
}

export interface AuditDTO {
    createdBy?: string;
    createdDate?: Instant;
    lastModifiedBy?: string;
    lastModifiedDate?: Instant;
}

export interface ConfigDTO {
    id?: number;
    proximoExpediente?: number;
}

export interface ConselleriaDTO {
    description?: string;
    disabled?: boolean;
    id?: number;
    name?: string;
    organos?: ItemDTO[];
}

export interface FichaDTO {
    benestar1?: number;
    benestar10?: number;
    benestar11?: number;
    benestar12?: number;
    benestar2?: number;
    benestar3?: number;
    benestar4?: number;
    benestar5?: number;
    benestar6?: number;
    benestar7?: number;
    benestar8?: number;
    benestar9?: number;
    cambioClimatico1?: number;
    cambioClimatico10?: number;
    cambioClimatico11?: number;
    cambioClimatico12?: number;
    cambioClimatico13?: number;
    cambioClimatico14?: number;
    cambioClimatico15?: number;
    cambioClimatico16?: number;
    cambioClimatico17?: number;
    cambioClimatico18?: number;
    cambioClimatico19?: number;
    cambioClimatico2?: number;
    cambioClimatico20?: number;
    cambioClimatico21?: number;
    cambioClimatico22?: number;
    cambioClimatico3?: number;
    cambioClimatico4?: number;
    cambioClimatico5?: number;
    cambioClimatico6?: number;
    cambioClimatico7?: number;
    cambioClimatico8?: number;
    cambioClimatico9?: number;
    economico1?: number;
    economico10?: number;
    economico11?: number;
    economico12?: number;
    economico13?: number;
    economico14?: number;
    economico15?: number;
    economico16?: number;
    economico17?: number;
    economico18?: number;
    economico19?: number;
    economico2?: number;
    economico20?: number;
    economico21?: number;
    economico22?: number;
    economico23?: number;
    economico3?: number;
    economico4?: number;
    economico5?: number;
    economico6?: number;
    economico7?: number;
    economico8?: number;
    economico9?: number;
    efectoBenestar?: string;
    efectoCambioClimatico?: string;
    efectoEconomico?: string;
    efectoFamiliar?: string;
    efectoFormacion?: string;
    efectoMobilidade?: string;
    efectoTerritorio?: string;
    familiar1?: number;
    familiar10?: number;
    familiar11?: number;
    familiar12?: number;
    familiar13?: number;
    familiar14?: number;
    familiar15?: number;
    familiar16?: number;
    familiar17?: number;
    familiar2?: number;
    familiar3?: number;
    familiar4?: number;
    familiar5?: number;
    familiar6?: number;
    familiar7?: number;
    familiar8?: number;
    familiar9?: number;
    formacion1?: number;
    formacion10?: number;
    formacion11?: number;
    formacion12?: number;
    formacion13?: number;
    formacion14?: number;
    formacion2?: number;
    formacion3?: number;
    formacion4?: number;
    formacion5?: number;
    formacion6?: number;
    formacion7?: number;
    formacion8?: number;
    formacion9?: number;
    id?: number;
    mobilidade1?: number;
    mobilidade10?: number;
    mobilidade11?: number;
    mobilidade12?: number;
    mobilidade13?: number;
    mobilidade14?: number;
    mobilidade2?: number;
    mobilidade3?: number;
    mobilidade4?: number;
    mobilidade5?: number;
    mobilidade6?: number;
    mobilidade7?: number;
    mobilidade8?: number;
    mobilidade9?: number;
    territorio1?: number;
    territorio10?: number;
    territorio11?: number;
    territorio12?: number;
    territorio13?: number;
    territorio14?: number;
    territorio15?: number;
    territorio16?: number;
    territorio17?: number;
    territorio18?: number;
    territorio19?: number;
    territorio2?: number;
    territorio20?: number;
    territorio21?: number;
    territorio22?: number;
    territorio23?: number;
    territorio24?: number;
    territorio25?: number;
    territorio26?: number;
    territorio27?: number;
    territorio28?: number;
    territorio29?: number;
    territorio3?: number;
    territorio30?: number;
    territorio31?: number;
    territorio32?: number;
    territorio33?: number;
    territorio4?: number;
    territorio5?: number;
    territorio6?: number;
    territorio7?: number;
    territorio8?: number;
    territorio9?: number;
}

export interface FwFileDTO {
    id?: number;
    name?: string;
    temporalFileName?: string;
}

export interface InformeFilter {
    acceso?: AccountDTO;
    dataFin?: string;
    dataInicio?: string;
    name?: string;
    tab?: string;
    text?: string;
    tipo?: string;
}

export interface InformeFormDTO {
    data?: Calendar;
    dataEnvio?: Calendar;
    dataRecepcion?: Calendar;
    estado?: string;
    estadoRequirimento?: string;
    expediente?: string;
    feitos?: string;
    id?: number;
    loginAsinante?: string;
    observacions?: string;
    pePaxina?: string;
    sinatura?: SinaturaDTO;
    solicitude?: SolicitudeFormDTO;
    titulo?: string;
    user?: UserListDTO;
    valoracion?: string;
}

export interface InformeListDTO {
    data?: Calendar;
    dataEnvio?: Calendar;
    dataRecepcion?: Calendar;
    estado?: string;
    expediente?: string;
    id?: number;
    observacions?: string;
    solicitude?: SolicitudeFormDTO;
    titulo?: string;
    user?: UserListDTO;
    valoracion?: string;
}

export interface InformeSimpleDTO {
    data?: Calendar;
    dataEnvio?: Calendar;
    dataRecepcion?: Calendar;
    estado?: string;
    expediente?: string;
    id?: number;
    titulo?: string;
    valoracion?: string;
}

export interface ItemDTO {
    disabled?: boolean;
    id?: number;
    name?: string;
}

export interface ItemFilter {
    text?: string;
}

export interface KeyAndPasswordDTO {
    key?: string;
    newPassword?: string;
}

export interface LanguageDTO {
    code?: string;
    default?: boolean;
    name?: string;
    translation?: string;
}

export interface OrganoDTO {
    conselleria?: ItemDTO;
    conselleriaId?: number;
    description?: string;
    disabled?: boolean;
    id?: number;
    name?: string;
}

export interface OrganoFilter {
    conselleriaId?: number;
    name?: string;
    text?: string;
}

export interface PasswordChangeDTO {
    currentPassword?: string;
    newPassword?: string;
}

export interface SinaturaDTO {
    estado?: string;
    filename?: string;
    id?: number;
    login?: string;
    path?: string;
}

export interface SolicitudeFilter {
    acceso?: AccountDTO;
    dataFin?: string;
    dataInicio?: string;
    name?: string;
    tab?: string;
    text?: string;
    tipo?: string;
}

export interface SolicitudeFormDTO {
    conselleriaId?: number;
    data?: Calendar;
    dataFin?: Calendar;
    dataInicio?: Calendar;
    espacial?: string;
    estado?: string;
    ficha?: FichaDTO;
    file?: FwFileDTO;
    filename?: string;
    id?: number;
    informe?: InformeSimpleDTO;
    intensidade?: string;
    loginAsinante?: string;
    organo?: OrganoDTO;
    organoId?: number;
    organoOutro?: string;
    path?: string;
    pePaxina?: string;
    requirimento?: string;
    sinatura?: SinaturaDTO;
    temporal?: string;
    tipoSolicitude?: string;
    titulo?: string;
    tramitar?: boolean;
    valoracion?: string;
}

export interface SolicitudeListDTO {
    data?: Calendar;
    dataFin?: Calendar;
    dataInicio?: Calendar;
    espacial?: string;
    estado?: string;
    id?: number;
    intensidade?: string;
    organo?: OrganoDTO;
    organoOutro?: string;
    temporal?: string;
    tipoSolicitude?: string;
    titulo?: string;
    valoracion?: string;
}

export interface UserBasicDTO {
    id?: number;
    label?: string;
    login?: string;
    version?: number;
}

export interface UserFilter {
    creationInstantFrom?: Instant;
    creationInstantTo?: Instant;
    name?: string;
    role?: string;
}

export interface UserFormDTO {
    audit?: AuditDTO;
    authorities?: string[];
    avatar?: FwFileDTO;
    blocked?: boolean;
    conselleria?: ItemDTO;
    conselleriaId?: number;
    email?: string;
    firstName?: string;
    id?: number;
    imageUrl?: string;
    langKey?: string;
    lastName?: string;
    login?: string;
    resetKey?: string;
    resetLink?: string;
    rol?: string;
    validated?: boolean;
    validationKey?: string;
    validationLink?: string;
    version?: number;
}

export interface UserFormNewDTO {
    authorities?: string[];
    email?: string;
    firstName?: string;
    imageUrl?: string;
    lastName?: string;
    login?: string;
    password?: string;
    validated?: boolean;
}

export interface UserListDTO {
    audit?: AuditDTO;
    authorities?: string[];
    blocked?: boolean;
    conselleria?: ItemDTO;
    email?: string;
    id?: number;
    imageUrl?: string;
    lastLogin?: Instant;
    login?: string;
    validated?: boolean;
}

export function registerDefaultSerializers(config: ApinaConfig) {
    config.registerIdentitySerializer('Calendar');
    config.registerIdentitySerializer('Instant');

    config.registerIdentitySerializer('Rol');

    config.registerClassSerializer('AccountDTO', {
        'authorities': 'string[]',
        'avatar': 'FwFileDTO',
        'avatarUrl': 'string',
        'conselleria': 'ItemDTO',
        'email': 'string',
        'firstName': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'loginUserOriginal': 'string',
        'rol': 'Rol',
        'userId': 'number',
        'validated': 'boolean',
        'version': 'number'
    });

    config.registerClassSerializer('AuditDTO', {
        'createdBy': 'string',
        'createdDate': 'Instant',
        'lastModifiedBy': 'string',
        'lastModifiedDate': 'Instant'
    });

    config.registerClassSerializer('ConfigDTO', {
        'id': 'number',
        'proximoExpediente': 'number'
    });

    config.registerClassSerializer('ConselleriaDTO', {
        'description': 'string',
        'disabled': 'boolean',
        'id': 'number',
        'name': 'string',
        'organos': 'ItemDTO[]'
    });

    config.registerClassSerializer('FichaDTO', {
        'benestar1': 'number',
        'benestar10': 'number',
        'benestar11': 'number',
        'benestar12': 'number',
        'benestar2': 'number',
        'benestar3': 'number',
        'benestar4': 'number',
        'benestar5': 'number',
        'benestar6': 'number',
        'benestar7': 'number',
        'benestar8': 'number',
        'benestar9': 'number',
        'cambioClimatico1': 'number',
        'cambioClimatico10': 'number',
        'cambioClimatico11': 'number',
        'cambioClimatico12': 'number',
        'cambioClimatico13': 'number',
        'cambioClimatico14': 'number',
        'cambioClimatico15': 'number',
        'cambioClimatico16': 'number',
        'cambioClimatico17': 'number',
        'cambioClimatico18': 'number',
        'cambioClimatico19': 'number',
        'cambioClimatico2': 'number',
        'cambioClimatico20': 'number',
        'cambioClimatico21': 'number',
        'cambioClimatico22': 'number',
        'cambioClimatico3': 'number',
        'cambioClimatico4': 'number',
        'cambioClimatico5': 'number',
        'cambioClimatico6': 'number',
        'cambioClimatico7': 'number',
        'cambioClimatico8': 'number',
        'cambioClimatico9': 'number',
        'economico1': 'number',
        'economico10': 'number',
        'economico11': 'number',
        'economico12': 'number',
        'economico13': 'number',
        'economico14': 'number',
        'economico15': 'number',
        'economico16': 'number',
        'economico17': 'number',
        'economico18': 'number',
        'economico19': 'number',
        'economico2': 'number',
        'economico20': 'number',
        'economico21': 'number',
        'economico22': 'number',
        'economico23': 'number',
        'economico3': 'number',
        'economico4': 'number',
        'economico5': 'number',
        'economico6': 'number',
        'economico7': 'number',
        'economico8': 'number',
        'economico9': 'number',
        'efectoBenestar': 'string',
        'efectoCambioClimatico': 'string',
        'efectoEconomico': 'string',
        'efectoFamiliar': 'string',
        'efectoFormacion': 'string',
        'efectoMobilidade': 'string',
        'efectoTerritorio': 'string',
        'familiar1': 'number',
        'familiar10': 'number',
        'familiar11': 'number',
        'familiar12': 'number',
        'familiar13': 'number',
        'familiar14': 'number',
        'familiar15': 'number',
        'familiar16': 'number',
        'familiar17': 'number',
        'familiar2': 'number',
        'familiar3': 'number',
        'familiar4': 'number',
        'familiar5': 'number',
        'familiar6': 'number',
        'familiar7': 'number',
        'familiar8': 'number',
        'familiar9': 'number',
        'formacion1': 'number',
        'formacion10': 'number',
        'formacion11': 'number',
        'formacion12': 'number',
        'formacion13': 'number',
        'formacion14': 'number',
        'formacion2': 'number',
        'formacion3': 'number',
        'formacion4': 'number',
        'formacion5': 'number',
        'formacion6': 'number',
        'formacion7': 'number',
        'formacion8': 'number',
        'formacion9': 'number',
        'id': 'number',
        'mobilidade1': 'number',
        'mobilidade10': 'number',
        'mobilidade11': 'number',
        'mobilidade12': 'number',
        'mobilidade13': 'number',
        'mobilidade14': 'number',
        'mobilidade2': 'number',
        'mobilidade3': 'number',
        'mobilidade4': 'number',
        'mobilidade5': 'number',
        'mobilidade6': 'number',
        'mobilidade7': 'number',
        'mobilidade8': 'number',
        'mobilidade9': 'number',
        'territorio1': 'number',
        'territorio10': 'number',
        'territorio11': 'number',
        'territorio12': 'number',
        'territorio13': 'number',
        'territorio14': 'number',
        'territorio15': 'number',
        'territorio16': 'number',
        'territorio17': 'number',
        'territorio18': 'number',
        'territorio19': 'number',
        'territorio2': 'number',
        'territorio20': 'number',
        'territorio21': 'number',
        'territorio22': 'number',
        'territorio23': 'number',
        'territorio24': 'number',
        'territorio25': 'number',
        'territorio26': 'number',
        'territorio27': 'number',
        'territorio28': 'number',
        'territorio29': 'number',
        'territorio3': 'number',
        'territorio30': 'number',
        'territorio31': 'number',
        'territorio32': 'number',
        'territorio33': 'number',
        'territorio4': 'number',
        'territorio5': 'number',
        'territorio6': 'number',
        'territorio7': 'number',
        'territorio8': 'number',
        'territorio9': 'number'
    });

    config.registerClassSerializer('FwFileDTO', {
        'id': 'number',
        'name': 'string',
        'temporalFileName': 'string'
    });

    config.registerClassSerializer('InformeFilter', {
        'acceso': 'AccountDTO',
        'dataFin': 'string',
        'dataInicio': 'string',
        'name': 'string',
        'tab': 'string',
        'text': 'string',
        'tipo': 'string'
    });

    config.registerClassSerializer('InformeFormDTO', {
        'data': 'Calendar',
        'dataEnvio': 'Calendar',
        'dataRecepcion': 'Calendar',
        'estado': 'string',
        'estadoRequirimento': 'string',
        'expediente': 'string',
        'feitos': 'string',
        'id': 'number',
        'loginAsinante': 'string',
        'observacions': 'string',
        'pePaxina': 'string',
        'sinatura': 'SinaturaDTO',
        'solicitude': 'SolicitudeFormDTO',
        'titulo': 'string',
        'user': 'UserListDTO',
        'valoracion': 'string'
    });

    config.registerClassSerializer('InformeListDTO', {
        'data': 'Calendar',
        'dataEnvio': 'Calendar',
        'dataRecepcion': 'Calendar',
        'estado': 'string',
        'expediente': 'string',
        'id': 'number',
        'observacions': 'string',
        'solicitude': 'SolicitudeFormDTO',
        'titulo': 'string',
        'user': 'UserListDTO',
        'valoracion': 'string'
    });

    config.registerClassSerializer('InformeSimpleDTO', {
        'data': 'Calendar',
        'dataEnvio': 'Calendar',
        'dataRecepcion': 'Calendar',
        'estado': 'string',
        'expediente': 'string',
        'id': 'number',
        'titulo': 'string',
        'valoracion': 'string'
    });

    config.registerClassSerializer('ItemDTO', {
        'disabled': 'boolean',
        'id': 'number',
        'name': 'string'
    });

    config.registerClassSerializer('ItemFilter', {
        'text': 'string'
    });

    config.registerClassSerializer('KeyAndPasswordDTO', {
        'key': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('LanguageDTO', {
        'code': 'string',
        'default': 'boolean',
        'name': 'string',
        'translation': 'string'
    });

    config.registerClassSerializer('OrganoDTO', {
        'conselleria': 'ItemDTO',
        'conselleriaId': 'number',
        'description': 'string',
        'disabled': 'boolean',
        'id': 'number',
        'name': 'string'
    });

    config.registerClassSerializer('OrganoFilter', {
        'conselleriaId': 'number',
        'name': 'string',
        'text': 'string'
    });

    config.registerClassSerializer('PasswordChangeDTO', {
        'currentPassword': 'string',
        'newPassword': 'string'
    });

    config.registerClassSerializer('SinaturaDTO', {
        'estado': 'string',
        'filename': 'string',
        'id': 'number',
        'login': 'string',
        'path': 'string'
    });

    config.registerClassSerializer('SolicitudeFilter', {
        'acceso': 'AccountDTO',
        'dataFin': 'string',
        'dataInicio': 'string',
        'name': 'string',
        'tab': 'string',
        'text': 'string',
        'tipo': 'string'
    });

    config.registerClassSerializer('SolicitudeFormDTO', {
        'conselleriaId': 'number',
        'data': 'Calendar',
        'dataFin': 'Calendar',
        'dataInicio': 'Calendar',
        'espacial': 'string',
        'estado': 'string',
        'ficha': 'FichaDTO',
        'file': 'FwFileDTO',
        'filename': 'string',
        'id': 'number',
        'informe': 'InformeSimpleDTO',
        'intensidade': 'string',
        'loginAsinante': 'string',
        'organo': 'OrganoDTO',
        'organoId': 'number',
        'organoOutro': 'string',
        'path': 'string',
        'pePaxina': 'string',
        'requirimento': 'string',
        'sinatura': 'SinaturaDTO',
        'temporal': 'string',
        'tipoSolicitude': 'string',
        'titulo': 'string',
        'tramitar': 'boolean',
        'valoracion': 'string'
    });

    config.registerClassSerializer('SolicitudeListDTO', {
        'data': 'Calendar',
        'dataFin': 'Calendar',
        'dataInicio': 'Calendar',
        'espacial': 'string',
        'estado': 'string',
        'id': 'number',
        'intensidade': 'string',
        'organo': 'OrganoDTO',
        'organoOutro': 'string',
        'temporal': 'string',
        'tipoSolicitude': 'string',
        'titulo': 'string',
        'valoracion': 'string'
    });

    config.registerClassSerializer('UserBasicDTO', {
        'id': 'number',
        'label': 'string',
        'login': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFilter', {
        'creationInstantFrom': 'Instant',
        'creationInstantTo': 'Instant',
        'name': 'string',
        'role': 'string'
    });

    config.registerClassSerializer('UserFormDTO', {
        'audit': 'AuditDTO',
        'authorities': 'string[]',
        'avatar': 'FwFileDTO',
        'blocked': 'boolean',
        'conselleria': 'ItemDTO',
        'conselleriaId': 'number',
        'email': 'string',
        'firstName': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'langKey': 'string',
        'lastName': 'string',
        'login': 'string',
        'resetKey': 'string',
        'resetLink': 'string',
        'rol': 'string',
        'validated': 'boolean',
        'validationKey': 'string',
        'validationLink': 'string',
        'version': 'number'
    });

    config.registerClassSerializer('UserFormNewDTO', {
        'authorities': 'string[]',
        'email': 'string',
        'firstName': 'string',
        'imageUrl': 'string',
        'lastName': 'string',
        'login': 'string',
        'password': 'string',
        'validated': 'boolean'
    });

    config.registerClassSerializer('UserListDTO', {
        'audit': 'AuditDTO',
        'authorities': 'string[]',
        'blocked': 'boolean',
        'conselleria': 'ItemDTO',
        'email': 'string',
        'id': 'number',
        'imageUrl': 'string',
        'lastLogin': 'Instant',
        'login': 'string',
        'validated': 'boolean'
    });

}

export class ApinaConfig {

    /** Prefix added for all API calls */
    baseUrl: string = "";

    private serializers: SerializerMap = {
        any: identitySerializer,
        string: identitySerializer,
        number: identitySerializer,
        boolean: identitySerializer
    };

    constructor() {
        registerDefaultSerializers(this);
    }

    serialize(value: any, type: string): any {
        return this.lookupSerializer(type).serialize(value);
    }

    deserialize(value: any, type: string): any {
        return this.lookupSerializer(type).deserialize(value);
    }

    registerSerializer(name: string, serializer: Serializer) {
        this.serializers[name] = serializer;
    }

    registerEnumSerializer(name: string, enumObject: any) {
        this.registerSerializer(name, enumSerializer(enumObject));
    }

    registerClassSerializer(name: string, fields: any) {
        this.registerSerializer(name, this.classSerializer(fields));
    }

    registerIdentitySerializer(name: string) {
        this.registerSerializer(name, identitySerializer);
    }

    registerDiscriminatedUnionSerializer(name: string, discriminator: string, types: { [key: string]: string; }) {
        this.registerSerializer(name, this.discriminatedUnionSerializer(discriminator, types));
    }

    private classSerializer(fields: any): Serializer {
        function mapProperties(obj: any, propertyMapper: (value: any, type: string) => any) {
            if (obj === null || obj === undefined) {
                return obj;
            }

            const result: any = {};

            for (const name in fields) {
                if (fields.hasOwnProperty(name)) {
                    const value: any = obj[name];
                    const type: string = fields[name];
                    result[name] = propertyMapper(value, type);
                }
            }

            return result;
        }

        const serialize = this.serialize.bind(this);
        const deserialize = this.deserialize.bind(this);
        return {
            serialize(obj) {
                return mapProperties(obj, serialize);
            },
            deserialize(obj) {
                return mapProperties(obj, deserialize);
            }
        };
    }

    private discriminatedUnionSerializer(discriminatorProperty: string, types: { [key: string]: string; }): Serializer {
        const resolveSerializer = (localType: string) => {
            return this.lookupSerializer(types[localType]);
        };

        return {
            serialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).serialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            },
            deserialize(obj) {
                if (obj == null) return null;

                const localType = obj[discriminatorProperty];
                const result = resolveSerializer(localType).deserialize(obj);
                result[discriminatorProperty] = localType;
                return result;
            }
        };
    }

    private lookupSerializer(type: string): Serializer {
        if (!type) throw new Error("no type given");

        if (type.indexOf('[]', type.length - 2) !== -1) { // type.endsWith('[]')
            const elementType = type.substring(0, type.length - 2);
            const elementSerializer = this.lookupSerializer(elementType);
            return arraySerializer(elementSerializer);
        }
        const serializer = this.serializers[type];
        if (serializer) {
            return serializer;
        } else {
            throw new Error(`could not find serializer for type '${type}'`);
        }
    }
}

function arraySerializer(elementSerializer: Serializer): Serializer {
    function safeMap(value: any[], mapper: (a: any) => any) {
        if (!value)
            return value;
        else
            return value.map(mapper);
    }

    return {
        serialize(value) {
            return safeMap(value, elementSerializer.serialize.bind(elementSerializer));
        },
        deserialize(value) {
            return safeMap(value, elementSerializer.deserialize.bind(elementSerializer));
        }
    }
}

export interface RequestData {
    uriTemplate: string;
    method: string;
    pathVariables?: any;
    requestParams?: any;
    requestBody?: any;
    responseType?: string;
}

export interface Serializer {
    serialize(o: any): any;
    deserialize(o: any): any;
}

const identitySerializer: Serializer = {
    serialize(o) {
        return o;
    },
    deserialize(o) {
        return o;
    }
};

function enumSerializer(enumObject: any): Serializer {
    return {
        serialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        },
        deserialize(o) {
            if (o === null || o === undefined)
                return o;
            else
                return enumObject[o];
        }
    }
}

interface SerializerMap {
    [name: string]: Serializer;
}

export abstract class ApinaEndpointContext {

    constructor(protected config: ApinaConfig) {
    }

    abstract request(data: RequestData): Observable<any>

    serialize(value: any, type: string): any {
        return this.config.serialize(value, type);
    }

    deserialize(value: any, type: string): any {
        return this.config.deserialize(value, type);
    }

    buildUrl(uriTemplate: String, pathVariables: any): string {
        return this.config.baseUrl + uriTemplate.replace(/{([^}]+)}/g, (match, name) => pathVariables[name]);
    }
}

@Injectable()
export class DefaultApinaEndpointContext extends ApinaEndpointContext {

    constructor(private httpClient: HttpClient, config: ApinaConfig) {
        super(config);
    }

    request(data: RequestData): Observable<any> {
        const url = this.buildUrl(data.uriTemplate, data.pathVariables);

        const requestParams = data.requestParams;
        let params: HttpParams | undefined = undefined;
        if (requestParams != null) {
            const filteredParams: { [key: string]: any }  = {};
            for (const key of Object.keys(requestParams)) {
                const value = requestParams[key];
                if (value != null)
                    filteredParams[key] = value;
            }

            params = new HttpParams({fromObject: filteredParams});
        }


        return this.httpClient.request(data.method, url, { params: params, body: data.requestBody })
            .pipe(map(r => data.responseType ? this.config.deserialize(r, data.responseType) : r));
    }
}

@Injectable( {providedIn: 'root'} )
export class AccountResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    changePassword(passwordChangeDto: PasswordChangeDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/account/change-password',
            'method': 'POST',
            'requestBody': this.context.serialize(passwordChangeDto, 'PasswordChangeDTO')
        });
    }

    changePasswordUrl(passwordChangeDto: PasswordChangeDTO): string {
        return this.context.buildUrl('/api/account/change-password' , null);
    }

    finishPasswordReset(keyAndPassword: KeyAndPasswordDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/account/reset-password/finish',
            'method': 'POST',
            'requestBody': this.context.serialize(keyAndPassword, 'KeyAndPasswordDTO')
        });
    }

    finishPasswordResetUrl(keyAndPassword: KeyAndPasswordDTO): string {
        return this.context.buildUrl('/api/account/reset-password/finish' , null);
    }

    getAccount(): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/api/account',
            'method': 'GET',
            'responseType': 'AccountDTO'
        });
    }

    getAccountUrl(): string {
        return this.context.buildUrl('/api/account' , null);
    }

    isAuthenticated(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/api/authenticate',
            'method': 'GET',
            'responseType': 'string'
        });
    }

    isAuthenticatedUrl(): string {
        return this.context.buildUrl('/api/authenticate' , null);
    }

    registerAccount(userFormNewDTO: UserFormNewDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/register',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormNewDTO, 'UserFormNewDTO')
        });
    }

    registerAccountUrl(userFormNewDTO: UserFormNewDTO): string {
        return this.context.buildUrl('/api/register' , null);
    }

    requestPasswordReset(mail: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/account/reset-password/init',
            'method': 'POST',
            'requestBody': this.context.serialize(mail, 'string')
        });
    }

    requestPasswordResetUrl(mail: string): string {
        return this.context.buildUrl('/api/account/reset-password/init' , null);
    }

    saveAccount(accountDTO: AccountDTO): Observable<AccountDTO> {
        return this.context.request({
            'uriTemplate': '/api/account',
            'method': 'POST',
            'requestBody': this.context.serialize(accountDTO, 'AccountDTO'),
            'responseType': 'AccountDTO'
        });
    }

    saveAccountUrl(accountDTO: AccountDTO): string {
        return this.context.buildUrl('/api/account' , null);
    }

    validateAccount(key: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/api/validate',
            'method': 'GET',
            'requestParams': {
                'key': this.context.serialize(key, 'string')
            }
        });
    }

    validateAccountUrl(key: string): string {
        return this.context.buildUrl('/api/validate' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class ConfigResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getConfig(): Observable<ConfigDTO> {
        return this.context.request({
            'uriTemplate': '/config/',
            'method': 'GET',
            'responseType': 'ConfigDTO'
        });
    }

    getConfigUrl(): string {
        return this.context.buildUrl('/config/' , null);
    }

    update(id: number, configDTO: ConfigDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/config/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(configDTO, 'ConfigDTO')
        });
    }

    updateUrl(id: number, configDTO: ConfigDTO): string {
        return this.context.buildUrl('/config/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class ConselleriaResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(conselleriaDTO: ConselleriaDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/consellerias',
            'method': 'POST',
            'requestBody': this.context.serialize(conselleriaDTO, 'ConselleriaDTO'),
            'responseType': 'number'
        });
    }

    createUrl(conselleriaDTO: ConselleriaDTO): string {
        return this.context.buildUrl('/consellerias' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/consellerias/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/consellerias/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(filtro: ItemFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/consellerias',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'ItemFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: ItemFilter, pageable: Pageable): string {
        return this.context.buildUrl('/consellerias' , null);
    }

    get(id: number): Observable<ConselleriaDTO> {
        return this.context.request({
            'uriTemplate': '/consellerias/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'ConselleriaDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/consellerias/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getAll(): Observable<ItemDTO[]> {
        return this.context.request({
            'uriTemplate': '/consellerias/all',
            'method': 'GET',
            'responseType': 'ItemDTO[]'
        });
    }

    getAllUrl(): string {
        return this.context.buildUrl('/consellerias/all' , null);
    }

    update(id: number, conselleriaDTO: ConselleriaDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/consellerias/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(conselleriaDTO, 'ConselleriaDTO')
        });
    }

    updateUrl(id: number, conselleriaDTO: ConselleriaDTO): string {
        return this.context.buildUrl('/consellerias/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class EnumResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getEspaciales(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/enums/especiales',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getEspacialesUrl(): string {
        return this.context.buildUrl('/enums/especiales' , null);
    }

    getIntensidades(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/enums/intensidades',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getIntensidadesUrl(): string {
        return this.context.buildUrl('/enums/intensidades' , null);
    }

    getTemporales(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/enums/temporales',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getTemporalesUrl(): string {
        return this.context.buildUrl('/enums/temporales' , null);
    }

    getTiposSolicitudes(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/enums/tipos-solicitudes',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getTiposSolicitudesUrl(): string {
        return this.context.buildUrl('/enums/tipos-solicitudes' , null);
    }

    getValoraciones(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/enums/valoraciones',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getValoracionesUrl(): string {
        return this.context.buildUrl('/enums/valoraciones' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class FicheroResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    downloadTemporalFile(temporalFileName: string, fileName: string, fileCodification: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/files/{temporalFileName}',
            'method': 'GET',
            'pathVariables': {
                'temporalFileName': this.context.serialize(temporalFileName, 'string')
            },
            'requestParams': {
                'fileName': this.context.serialize(fileName, 'string'),
                'fileCodification': this.context.serialize(fileCodification, 'string')
            }
        });
    }

    downloadTemporalFileUrl(temporalFileName: string, fileName: string, fileCodification: string): string {
        return this.context.buildUrl('/files/{temporalFileName}' , {
            'temporalFileName': this.context.serialize(temporalFileName, 'string')
        });
    }

    saveTemporalFile(): Observable<string> {
        return this.context.request({
            'uriTemplate': '/files/temporal',
            'method': 'POST',
            'responseType': 'string'
        });
    }

    saveTemporalFileUrl(): string {
        return this.context.buildUrl('/files/temporal' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class InformeResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    asignar(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/informes/asignar/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    asignarUrl(id: number): string {
        return this.context.buildUrl('/informes/asignar/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    create(informeFormDTO: InformeFormDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/informes',
            'method': 'POST',
            'requestBody': this.context.serialize(informeFormDTO, 'InformeFormDTO'),
            'responseType': 'number'
        });
    }

    createUrl(informeFormDTO: InformeFormDTO): string {
        return this.context.buildUrl('/informes' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/informes/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/informes/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    desasignar(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/informes/desasignar/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    desasignarUrl(id: number): string {
        return this.context.buildUrl('/informes/desasignar/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    downloadPDF(item: ItemDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/informes/downloadPdf',
            'method': 'GET',
            'requestParams': {
                'item': this.context.serialize(item, 'ItemDTO')
            }
        });
    }

    downloadPDFUrl(item: ItemDTO): string {
        return this.context.buildUrl('/informes/downloadPdf' , null);
    }

    downloadSignedPDF(sinatura: SinaturaDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/informes/downloadSignedPdf',
            'method': 'GET',
            'requestParams': {
                'sinatura': this.context.serialize(sinatura, 'SinaturaDTO')
            }
        });
    }

    downloadSignedPDFUrl(sinatura: SinaturaDTO): string {
        return this.context.buildUrl('/informes/downloadSignedPdf' , null);
    }

    finalizar(id: number, login: string): Observable<boolean> {
        return this.context.request({
            'uriTemplate': '/informes/tramitar/{id}/{login}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number'),
                'login': this.context.serialize(login, 'string')
            },
            'responseType': 'boolean'
        });
    }

    finalizarUrl(id: number, login: string): string {
        return this.context.buildUrl('/informes/tramitar/{id}/{login}' , {
            'id': this.context.serialize(id, 'number'),
            'login': this.context.serialize(login, 'string')
        });
    }

    findAll(filtro: InformeFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/informes',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'InformeFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: InformeFilter, pageable: Pageable): string {
        return this.context.buildUrl('/informes' , null);
    }

    generarPdf(id: number): Observable<ItemDTO> {
        return this.context.request({
            'uriTemplate': '/informes/generarPdf/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'ItemDTO'
        });
    }

    generarPdfUrl(id: number): string {
        return this.context.buildUrl('/informes/generarPdf/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    get(id: number): Observable<InformeFormDTO> {
        return this.context.request({
            'uriTemplate': '/informes/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'InformeFormDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/informes/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, informeFormDTO: InformeFormDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/informes/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(informeFormDTO, 'InformeFormDTO')
        });
    }

    updateUrl(id: number, informeFormDTO: InformeFormDTO): string {
        return this.context.buildUrl('/informes/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class LanguageResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    getCurrentLanguage(): Observable<LanguageDTO> {
        return this.context.request({
            'uriTemplate': '/languages/current',
            'method': 'GET',
            'responseType': 'LanguageDTO'
        });
    }

    getCurrentLanguageUrl(): string {
        return this.context.buildUrl('/languages/current' , null);
    }

    getLanguages(): Observable<LanguageDTO[]> {
        return this.context.request({
            'uriTemplate': '/languages',
            'method': 'GET',
            'responseType': 'LanguageDTO[]'
        });
    }

    getLanguagesUrl(): string {
        return this.context.buildUrl('/languages' , null);
    }

}

@Injectable( {providedIn: 'root'} )
export class OrganoResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    create(organoDTO: OrganoDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/organos',
            'method': 'POST',
            'requestBody': this.context.serialize(organoDTO, 'OrganoDTO'),
            'responseType': 'number'
        });
    }

    createUrl(organoDTO: OrganoDTO): string {
        return this.context.buildUrl('/organos' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/organos/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/organos/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    findAll(id: number, filtro: OrganoFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/organos/conselleria/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'OrganoFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(id: number, filtro: OrganoFilter, pageable: Pageable): string {
        return this.context.buildUrl('/organos/conselleria/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    get(id: number): Observable<OrganoDTO> {
        return this.context.request({
            'uriTemplate': '/organos/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'OrganoDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/organos/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getAll(): Observable<ItemDTO[]> {
        return this.context.request({
            'uriTemplate': '/organos/all',
            'method': 'GET',
            'responseType': 'ItemDTO[]'
        });
    }

    getAllUrl(): string {
        return this.context.buildUrl('/organos/all' , null);
    }

    update(id: number, organoDTO: OrganoDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/organos/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(organoDTO, 'OrganoDTO')
        });
    }

    updateUrl(id: number, organoDTO: OrganoDTO): string {
        return this.context.buildUrl('/organos/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class SolicitudeResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    clonarSolicitude(id: number): Observable<SolicitudeFormDTO> {
        return this.context.request({
            'uriTemplate': '/solicitudes/clone/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'SolicitudeFormDTO'
        });
    }

    clonarSolicitudeUrl(id: number): string {
        return this.context.buildUrl('/solicitudes/clone/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    create(solicitudeFormDTO: SolicitudeFormDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/solicitudes',
            'method': 'POST',
            'requestBody': this.context.serialize(solicitudeFormDTO, 'SolicitudeFormDTO'),
            'responseType': 'number'
        });
    }

    createUrl(solicitudeFormDTO: SolicitudeFormDTO): string {
        return this.context.buildUrl('/solicitudes' , null);
    }

    delete(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/solicitudes/{id}',
            'method': 'DELETE',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    deleteUrl(id: number): string {
        return this.context.buildUrl('/solicitudes/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    downloadPDF(item: ItemDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/solicitudes/downloadPdf',
            'method': 'GET',
            'requestParams': {
                'item': this.context.serialize(item, 'ItemDTO')
            }
        });
    }

    downloadPDFUrl(item: ItemDTO): string {
        return this.context.buildUrl('/solicitudes/downloadPdf' , null);
    }

    downloadSignedPDF(sinatura: SinaturaDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/solicitudes/downloadSignedPdf',
            'method': 'GET',
            'requestParams': {
                'sinatura': this.context.serialize(sinatura, 'SinaturaDTO')
            }
        });
    }

    downloadSignedPDFUrl(sinatura: SinaturaDTO): string {
        return this.context.buildUrl('/solicitudes/downloadSignedPdf' , null);
    }

    findAll(filtro: SolicitudeFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/solicitudes',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'SolicitudeFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: SolicitudeFilter, pageable: Pageable): string {
        return this.context.buildUrl('/solicitudes' , null);
    }

    generarPdf(id: number): Observable<ItemDTO> {
        return this.context.request({
            'uriTemplate': '/solicitudes/generarPdf/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'ItemDTO'
        });
    }

    generarPdfUrl(id: number): string {
        return this.context.buildUrl('/solicitudes/generarPdf/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    get(id: number): Observable<SolicitudeFormDTO> {
        return this.context.request({
            'uriTemplate': '/solicitudes/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'SolicitudeFormDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/solicitudes/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getFile(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/solicitudes/{id}/file',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    getFileUrl(id: number): string {
        return this.context.buildUrl('/solicitudes/{id}/file' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getNew(): Observable<SolicitudeFormDTO> {
        return this.context.request({
            'uriTemplate': '/solicitudes/new',
            'method': 'GET',
            'responseType': 'SolicitudeFormDTO'
        });
    }

    getNewUrl(): string {
        return this.context.buildUrl('/solicitudes/new' , null);
    }

    tramitar(id: number, login: string): Observable<boolean> {
        return this.context.request({
            'uriTemplate': '/solicitudes/tramitar/{id}/{login}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number'),
                'login': this.context.serialize(login, 'string')
            },
            'responseType': 'boolean'
        });
    }

    tramitarUrl(id: number, login: string): string {
        return this.context.buildUrl('/solicitudes/tramitar/{id}/{login}' , {
            'id': this.context.serialize(id, 'number'),
            'login': this.context.serialize(login, 'string')
        });
    }

    update(id: number, solicitudeFormDTO: SolicitudeFormDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/solicitudes/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(solicitudeFormDTO, 'SolicitudeFormDTO')
        });
    }

    updateUrl(id: number, solicitudeFormDTO: SolicitudeFormDTO): string {
        return this.context.buildUrl('/solicitudes/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}

@Injectable( {providedIn: 'root'} )
export class UserResourceEndpoint {
    constructor(private context: ApinaEndpointContext) {
    }

    addAdmin(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/addAdmin/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    addAdminUrl(id: number): string {
        return this.context.buildUrl('/users/addAdmin/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    block(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/block/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    blockUrl(id: number): string {
        return this.context.buildUrl('/users/block/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    create(userFormDTO: UserFormDTO): Observable<number> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'POST',
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO'),
            'responseType': 'number'
        });
    }

    createUrl(userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users' , null);
    }

    findAll(filtro: UserFilter, pageable: Pageable): Observable<Page> {
        return this.context.request({
            'uriTemplate': '/users',
            'method': 'GET',
            'requestParams': {
                'filtro': this.context.serialize(filtro, 'UserFilter'),
                'pageable': this.context.serialize(pageable, 'Pageable')
            },
            'responseType': 'Page'
        });
    }

    findAllUrl(filtro: UserFilter, pageable: Pageable): string {
        return this.context.buildUrl('/users' , null);
    }

    findAllOrdenadosPorLogin(): Observable<UserBasicDTO[]> {
        return this.context.request({
            'uriTemplate': '/users/todos',
            'method': 'GET',
            'responseType': 'UserBasicDTO[]'
        });
    }

    findAllOrdenadosPorLoginUrl(): string {
        return this.context.buildUrl('/users/todos' , null);
    }

    get(id: number): Observable<UserFormDTO> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'GET',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'responseType': 'UserFormDTO'
        });
    }

    getUrl(id: number): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    getAuthorities(): Observable<string[]> {
        return this.context.request({
            'uriTemplate': '/users/authorities',
            'method': 'GET',
            'responseType': 'string[]'
        });
    }

    getAuthoritiesUrl(): string {
        return this.context.buildUrl('/users/authorities' , null);
    }

    getAvatar(login: string, avatarId: string): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{login}/{avatarId}/avatar.jpg',
            'method': 'GET',
            'pathVariables': {
                'login': this.context.serialize(login, 'string'),
                'avatarId': this.context.serialize(avatarId, 'string')
            }
        });
    }

    getAvatarUrl(login: string, avatarId: string): string {
        return this.context.buildUrl('/users/{login}/{avatarId}/avatar.jpg' , {
            'login': this.context.serialize(login, 'string'),
            'avatarId': this.context.serialize(avatarId, 'string')
        });
    }

    quitBlock(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/quitBlock/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    quitBlockUrl(id: number): string {
        return this.context.buildUrl('/users/quitBlock/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    removeAdmin(id: number): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/removeAdmin/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            }
        });
    }

    removeAdminUrl(id: number): string {
        return this.context.buildUrl('/users/removeAdmin/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

    update(id: number, userFormDTO: UserFormDTO): Observable<void> {
        return this.context.request({
            'uriTemplate': '/users/{id}',
            'method': 'PUT',
            'pathVariables': {
                'id': this.context.serialize(id, 'number')
            },
            'requestBody': this.context.serialize(userFormDTO, 'UserFormDTO')
        });
    }

    updateUrl(id: number, userFormDTO: UserFormDTO): string {
        return this.context.buildUrl('/users/{id}' , {
            'id': this.context.serialize(id, 'number')
        });
    }

}


export function apinaConfigFactory() {
    return new ApinaConfig();
}

@NgModule({
    imports: [HttpClientModule],
    providers: [
        { provide: ApinaEndpointContext, useClass: DefaultApinaEndpointContext },
        { provide: ApinaConfig, useFactory: apinaConfigFactory }
    ]
})
export class ApinaModule {}
