import {NgModule} from '@angular/core';
import {
    NgbAlertModule,
    NgbDateAdapter,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule
} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {NgSelectConfig, NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Principal} from 'app/security/principal.service';
import {CustomFormsModule} from 'ngx-custom-validators';
import {FwFilesConfig, FwFilesModule} from 'fw-files';
import {
    FwAuthModule,
    FwCommonConfig,
    FwCommonModule,
    FwFormsConfig,
    FwFormsModule,
    FwPaginationConfig,
    FwPaginationModule,
    FwPrincipalService,
    NgbDateStringAdapter
} from 'fw-core';
import {AlertErrorComponent} from 'app/shared/alert-error.component';

@NgModule({
    imports: [FormsModule, CustomFormsModule, FwCommonModule, FwAuthModule, FwFilesModule,
        CommonModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, FwFormsModule,
        FwPaginationModule,
        RouterModule, NgSelectModule, NgbAlertModule],
    declarations: [AlertErrorComponent],
    providers: [
        {provide: NgbDateAdapter, useClass: NgbDateStringAdapter},
        {provide: FwPrincipalService, useClass: Principal}],
    exports: [FwFormsModule, CustomFormsModule, AlertErrorComponent,
        NgSelectModule, FormsModule, CommonModule, FwAuthModule, FwCommonModule,
        NgbDatepickerModule, NgbModalModule, NgbDropdownModule, NgbAlertModule, FwPaginationModule]
})
export class SharedModule {

    constructor(private config: NgSelectConfig, private commonConfig: FwCommonConfig,
        private paginationConfig: FwPaginationConfig, private formsConfig: FwFormsConfig,
        private filesConfig: FwFilesConfig) {

        this.config.notFoundText = $localize`Not found`;

        this.commonConfig.i18n = {
            confirmOk: $localize`Sí`,
            confirmCancel: $localize`Non`,
            confirmTitle: $localize`Confirmación de borrado`,
            connectionErrorToastMessage: $localize`Non se puido establecer a conexión co servidor, comprobe que tes acceso a internet. Se o problema persiste póñase en contacto co administrador.`
        };

        this.paginationConfig.pageSize = 10;

        this.formsConfig.i18n = {
            save: $localize`Gardar`,
            saveContinue: $localize`Gardar e continuar`,
            emptyList: $localize`Non hay elementos`,
            validationDefault: $localize`Erro de validación: `,
            validationEmail: $localize`O email non é válido`,
            validationRequired: $localize`Campo requerido`,
            validationInvalidPattern: $localize`Patrón incorrecto`,
            validationMaxLength1: $localize`Debe ter como máximo `,
            validationMaxLength2: $localize` caracteres`,
            validationMinLength1: $localize`Debe ter como mínimo `,
            validationMinLength2: $localize` caracteres`,
            booleanNo: $localize`Non`,
            booleanYes: $localize`Sí`,
        };

        this.filesConfig.urlCreateArchivoTemporal = 'api/files/temporal';
        this.filesConfig.urlCreateArchivoTemporal = 'api/files/temporal';
        this.filesConfig.urlFicheroTemporal = 'api/files';
        this.filesConfig.urlImagenDefault = 'content/img/default.jpg';
        this.filesConfig.i18n = {
            imageLoading: $localize`Cargando`,
            imageChange: $localize`Cambiar`
        };

    }
}
